import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';



import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const LangSelector = () => {
    const { i18n, t } = useTranslation();

    const [selectedLang, setSelectedlang] = useState('en');
    
    const changeLanguage = (event) => {
        setSelectedlang(event.target.value)
        i18n.changeLanguage(event.target.value);
    }

    return (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
   
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedLang}
              //label={t('language')}
              onChange={changeLanguage}
            >
              <MenuItem value="en">{t('en')}</MenuItem>
              <MenuItem value="fr">{t('fr')}</MenuItem>
             
            </Select>
          </FormControl>
        </Box>
      );


};

export default LangSelector;