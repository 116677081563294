export const projectsData = [
  {
    id: 1,
    title: "Casta",
    date: "Janvier 2020",
    languages: ["React", "Php", "Sass"],
    infos:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas cumque labore suscipit, pariatur laboriosam autem omnis saepe quisquam enim iste.",
    img: "./assets/img/projet-2.jpg",
    link: "http://www.google.com",
  },
  {
    id: 2,
    title: "Lyon Béton",
    date: "Mars 2020",
    languages: ["Symfony", "Vue"],
    infos:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas cumque labore suscipit, pariatur laboriosam autem omnis saepe quisquam enim iste.",
    img: "./assets/img/projet-1.jpg",
    link: "http://www.google.com",
  },
  {
    id: 3,
    title: "Everpost",
    date: "Avril 2020",
    languages: ["Wordpress", "Php", "React"],
    infos:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas cumque labore suscipit, pariatur laboriosam autem omnis saepe quisquam enim iste.",
    img: "./assets/img/projet-3.jpg",
    link: "http://www.google.com",
  },
  {
    id: 4,
    title: "Creative Dev",
    date: "Juillet 2020",
    languages: ["Vue", "Php"],
    infos:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas cumque labore suscipit, pariatur laboriosam autem omnis saepe quisquam enim iste.",
    img: "./assets/img/projet-4.jpg",
    link: "http://www.google.com",
  },
  {
    id: 5,
    title: "Creative Dev",
    date: "Fevrier 2021",
    languages: ["react", "Laravel"],
    infos:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas cumque labore suscipit, pariatur laboriosam autem omnis saepe quisquam enim iste.",
    img: "./assets/img/projet-4.jpg",
    link: "http://www.google.com",
  },
];

export default projectsData;
