import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Navigation = () => {
    
    const { t } = useTranslation();

    return (
      <div className="navigation">
          <ul>
              <NavLink to="/" exact className="hover" activeClassName="nav-active" >

                  <li>{t('welcome')} </li>

              </NavLink>

                <li className="nav-portfolio">{t('summary')}
                    <ul className="nav-projects">
                        <NavLink to="/Question-1" activeClassName="nav-active" className="hover">
                            <li>
                                Question 1
                            </li>
                        </NavLink>
                        <NavLink to="/Question-2" activeClassName="nav-active" className="hover">
                            <li>
                                Question 2
                            </li>
                        </NavLink>
                        <NavLink to="/Question-3" activeClassName="nav-active" className="hover">
                            <li>
                                Question 3
                            </li>
                        </NavLink>
                        <NavLink to="/Question-4" activeClassName="nav-active" className="hover">
                            <li>
                                Question 4
                            </li>
                        </NavLink>
                        <NavLink to="/Question-5" activeClassName="nav-active" className="hover">
                            <li>
                                Question 5
                            </li>
                        </NavLink>
                        <NavLink to="/Question-6" activeClassName="nav-active" className="hover">
                            <li>
                                Question 6
                            </li>
                        </NavLink>
                    </ul>
                </li>

                <NavLink to="/contact" exact className="hover" activeClassName="nav-active" >

                    <li>{t('contact')}</li>

                </NavLink>

                
          </ul>
      </div>
    );
};

export default Navigation;