import React, { useState, useEffect } from "react";

import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { NB_QUESTIONS } from "../../config/constants";


import Button from '@mui/material/Button';

import Menu from './Menu';
import Question1 from './Question1';
import Question2 from './Question2';
import Question3 from './Question3';
import Question4 from './Question4';
import Question5 from './Question5';







const Home = () => {


    const [step, setStep] = useState(1);
    const [sector, setSector] = useState(false);
    const [question2, setQuestion2] = useState(false);
    const [question3, setQuestion3] = useState(false);
    const [question4, setQuestion4] = useState(false);
    const [question5, setQuestion5] = useState(false);


    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    const _displayQuestion = () => {


        if(step === 5) {
            return (<Question5 />);
        }

        if(step === 4) {
            return (<Question4 />);
        }

        if(step === 3) {
            return (<Question3 />);
        }

        if(step === 2) {
            return (<Question2 />);
        }

        return (<Question1 value={sector} onChange={(v) => {
            setSector(v.target.value);
        }} />);
    }

    return (
        <main>

            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Item>
                        <Menu step={step} onChange={setStep}  />
                    </Item>
                </Grid>
                <Grid item xs={10}>
                    <Item>
                        {_displayQuestion()}
                        


                        <Grid container spacing={1} style={{ marginTop: 50 }}>
                            <Grid item xs={6}>
                                {
                                    step > 1 && (
                                        <Button onClick={() => setStep(step-1)} variant="contained">Prev</Button>
                                    )
                                }
                                
                            </Grid>

                            <Grid item xs={6}>
                                {
                                    NB_QUESTIONS > step && (
                                        <Button onClick={() => setStep(step+1)} variant="contained">Next</Button>
                                    )
                                }
                                
                            </Grid>        

                        </Grid>   
                      

                    </Item>
                </Grid>
            </Grid>



        </main>
    );

};


export default Home;