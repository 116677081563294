import React from "react";
import Navigation from "../components/Navigation";
import ButtonBottoms from "../components/ButtonBottoms";
import ContactForm from "../components/ContactForm";
import SocialNetwork from "../components/SocialNetwork";
import Logo from "../components/Logo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Mouse from "../components/Mouse";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Contact = () => {

const {t} = useTranslation();


const variants = {
    in: {
        opacity: 1,
        x: 0
    },
    out: {
        opacity: 0,
        x: 300,
    }
}
const transition = {
    ease : [.03,.87,.73,.9],
    duration: .6 
}

    return (
        <main>
            <Mouse />
            <motion.div 
                className="contact"
                exit="out"
                animate="in"
                initial="out"
                variants={variants}
                transition={transition}
            >
                <Navigation />
                <Logo />
                <ContactForm />
                <div
                    className="contact-infos">
                    <div className="address">
                        <div className="content">
                            <h4> {t('address')} </h4>
                            <p>5 rue de Lancry</p>
                            <p>75010 Paris</p>
                        </div>
                    </div>

                    <div className="phone">
                        <div className="content">
                            <h4> {t('phone number')} </h4>
                            <CopyToClipboard text="01 42 40 91 02" className="hover">
                                <p style={{ cursor: 'pointer' }} className="clipboard" onClick={() => {

                                    alert('téléphone copié');

                                }} >01 42 40 91 02</p>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="email">
                        <div className="content">
                            <h4>Email</h4>
                            <CopyToClipboard text="igor@schlum.com" className="hover">
                                <p style={{cursor: 'pointer'}} className="clipboard" onClick={() => {
                                    alert('email copié ');
                                }} >igor@schlum.com</p>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <SocialNetwork />
                    <div className="credits">
                        <p>Foound - 2021</p>
                    </div>
                </div>
                
                <ButtonBottoms left={'/Question-6'} right={'/'} />
            </motion.div>
        </main>
    );
};

export default Contact;