import React from 'react';
import Navigation from '../components/Navigation'
import ButtonBottoms from '../components/ButtonBottoms'
import Logo from '../components/Logo'
import Questions from '../components/Questions';
import Mouse from '../components/Mouse';


export const Question1 = () => {
    return (
        <main>
            <Mouse />
            <div className="project">
                <Logo />
                <Navigation />
                <Questions questionNumber={0} />
                <ButtonBottoms left={'/'} right={'/Question-2'} />
            </div>
        </main>
    ) 
}
export const Question2 = () => {
    return (
        <main>
            <Mouse />
            <div className="project">
                <Logo />
                <Navigation />
                <Questions questionNumber={1} />
                <ButtonBottoms left={'/Question-1'} right={'/Question-3'} />
            </div>
        </main>
    ) 
}
export const Question3 = () => {
    return (
        <main>
            <Mouse />
            <div className="project">
                <Logo />
                <Navigation />
                <Questions questionNumber={3} />
                <ButtonBottoms left={'/Question-2'} right={'/Question-4'} />
            </div>
        </main>
    ) 
}
export const Question4 = () => {
    return (
        <main>
            <Mouse />
            <div className="project">
                <Logo />
                <Navigation />
                <Questions questionNumber={4} />
                <ButtonBottoms left={'/Question-3'} right={'/Question-5'} />
            </div>
        </main>
    ) 
}
export const Question5 = () => {
    return (
        <main>
            <Mouse />
            <div className="project">
                <Logo />
                <Navigation />
                <Questions questionNumber={4} />
                <ButtonBottoms left={'/Question-4'} right={'/Question-6'} />
            </div>
        </main>
    ) 
}
export const Question6 = () => {
    return (
        <main>
            <Mouse />
            <div className="project">
                <Logo />
                <Navigation />
                <Questions questionNumber={4} />
                <ButtonBottoms left={'/Question-5'} right={'/contact'} />
            </div>
        </main>
    ) 
}