import * as React from 'react';
import Box from '@mui/material/Box';
import LangSelector from './LangSelector';

export default function BasicTabs() {


  return (
    <Box sx={{ width: '100%' }}>
      <LangSelector />
    </Box>
  );
}
