import React from 'react';
import { NavLink } from 'react-router-dom';

const Logo = () => {
    return (
        <div>
            <a className="logo" href={<NavLink to="/" exact />}>WF</a>
        </div>
    );
};

export default Logo;