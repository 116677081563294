import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { useTranslation } from "react-i18next";
import { NB_QUESTIONS } from "../../config/constants";

export default function App(props) {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <nav aria-label="main mailbox folders">
        <List>
          {Array.apply(null, Array(NB_QUESTIONS)).map((k, index) => {
            
            const nb = index + 1;
            const q = t(`question_${nb}`);

            return (
              <ListItem disablePadding selected={props.step === nb} onClick={() => {
                props.onChange(nb);
              }}  >
                <ListItemButton>
                  <ListItemText primary={t(q)} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </nav>
    </Box>
  );
}
