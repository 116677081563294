import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import './style/index.scss';
import App from './App';
import { Suspense } from 'react';

ReactDOM.render(
  <Suspense fallback={<span> Loading....</span>}>
    <Router>
      <App />
    </Router>
  </Suspense>,

  document.getElementById("root")
);