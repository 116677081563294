import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Contact from './pages/Contact';
import Home from './pages/Home/index';
import { Question1, Question2, Question3, Question4, Question5, Question6 } from './pages/Projects';
import { AnimatePresence } from 'framer-motion';
import i18n from './i18n';
import Header from './components/Header';
import { useEffect } from 'react';


const App = () => {

   useEffect(() => {
      fetch('https://api.foound.com/api/v1/onboarding/siret/814438933600020').then((response) => {
          if(response.ok) { 
             return response.json();
          }
          }).then((data) => {
            console.log(data)  
      });
     }, []);




    return (
       <AnimatePresence>
           <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Question-1" component={Question1} />
            <Route exact path="/Question-2" component={Question2} />
            <Route exact path="/Question-3" component={Question3} />
            <Route exact path="/Question-4" component={Question4} />
            <Route exact path="/Question-5" component={Question5} />
            <Route exact path="/Question-6" component={Question6} />
            <Route exact path="/contact" component={Contact} />
            <Redirect to="/" />
          </Switch>
          
       </AnimatePresence>
    );
};

export default App;