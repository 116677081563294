import * as React from 'react';
import Box from '@mui/material/Box';


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";

export default function App(props) {

    const {t} = useTranslation();

  return (
    <Box
    component="form"
    sx={{
      '& > :not(style)': { m: 1, width: '25ch' },
    }}
    noValidate
    autoComplete="off"
  >


            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.value}
              //label={t('language')}
              onChange={props.onChange}
            >
                
              <MenuItem value="sector1">sector1</MenuItem>
              <MenuItem value="sector2">sector2</MenuItem>
              <MenuItem value="sector3">sector3</MenuItem>
              <MenuItem value="sector4">sector4</MenuItem>
                 
            </Select>

            

   
  </Box>
  );
}
